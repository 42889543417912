<template>
  <div class="order-box">
    <nav-top :title="'出库'"></nav-top>
    <div class="content">
      <div class="content-top">
        <div class="content-title">
          <div class="content-title-left">
            <p>订单码</p>
          </div>
          <div class="content-title-right" v-if="!orderNo.includes('订单码')">
            <button @click="clear('order')">清空</button>
          </div>
        </div>
        <div class="shelf-code">{{orderNo}}</div>
      </div>
      <div class="content-center">
        <div class="content-title">
          <div class="content-title-left">
            <p>出库清单</p>
          </div>
          <div class="content-title-right" v-if="list.length!==0">
            <button @click="clear('content')">清空</button>
          </div>
        </div>
        <div class="content-text-all" v-if="list.length!==0">
          <div class="content-text" v-for="(item,index) in list" :key="index">
            <div class="content-text-img">
              <img :src="item.completeNumber == item.goodsNumber ? accomplish : unfinished">
            </div>
            <div class="content-text-center">
              {{item.categoryName+'|'+item.brandName+'|'+item.goodsName+'|'+item.factoryNo}}
            </div>
            <div class="content-text-right">
              {{item.completeNumber}}<span>/</span>{{item.goodsNumber}}
            </div>
          </div>
        </div>
        <div class="empty" v-if="list.length===0">请先扫描订单码</div>
      </div>
    </div>
    <div class="notarize">
      <button @click="scan()">扫描</button>
      <button @click="outMessage()">确认出库</button>
    </div>
    <nav-bar ></nav-bar>
  </div>
</template>


<script>
import navBar from '@/components/NavBar'
import navTop from '@/components/NavTop'
import {
  getGoodsSkuDetailByStoreNo,
  getOrderDetailInfoByNo,
  submitOutStorage,
  submitPutStorage,
  wxScan
} from "@/service/ware";
import {Toast} from "vant";
export default {
  data() {
    return {
      accomplish: require('@/assets/accomplish.png'),
      unfinished: require('@/assets/unfinished.png'),
      num: false,
      list: [],
      str: '',
      operation: '',
      orderNo:'请扫描订单码',//订单码
      storeNos:[]
    }
  },
  components: {
    navBar,
    navTop
  },
  async mounted() {
    //测试数据
   //this.orderNo = "20211211120838778376";
   //this.getDetail("20211211120838778376");
   //this.getSkuDetail("CG2021121013-SHJD01-BZ-022");
    //this.getSkuDetail("CG2021121010-FJXMJM-LG-018");
  },
  methods: {
    async wxAuthConfig(){
      let params ={
        url:location.href
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let _this = this;
      const { data } = await wxScan(params)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见附录1
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      Toast.clear();
      wx.ready(function () {
        wx.scanQRCode({
          needResult:1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            if(/^\d+$/.test(result)){ //如果是全数字，则是订单码
              _this.orderNo = result;
              _this.getDetail(result);
            }else if(result.includes('CG')){
              _this.getSkuDetail(result);
            }else{
              Toast({message:"扫描错误，请重新扫描！",duration:2500})
            }
          }
        });
      });
      wx.error(function(){
        location.reload();
      })
    },
    clear(str){
      if(str==="order"){
         this.orderNo = "请扫描订单码";
         this.storeNos = [];
         this.list = [];
      }else if(str==="content"){
        Toast.loading({
          message: '清空中...',
          forbidClick: true
        });
        this.storeNos=[];
        this.list.forEach((item,index,array)=> {
          item.completeNumber = 0;
          this.$set(this.list,index,this.list[index])
        })
        Toast.clear();
      }
    },
    scan(){
      this.wxAuthConfig();
    },
    async getSkuDetail(storeNo){
      if(this.storeNos.includes(storeNo)){
        Toast({message:"您已经扫描过了，不需要再扫了！",duration:2500})
        return;
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let param ={
        storeNo:storeNo
      };
      const res = await getGoodsSkuDetailByStoreNo(param);
      Toast.clear();
      if(res.code===0){
        let result = this.list.filter(i=>i.skuId===res.data.skuId);
        if(result.length!==0){
          this.list.forEach((item,index)=> {
            if(item.skuId === res.data.skuId){
              item.completeNumber = item.completeNumber+1;
              this.$set(this.list,index,this.list[index])
              this.storeNos.push(storeNo);
            }
          })
        }else{
          Toast({message:"当前库存码不属于该订单！",duration:3000})
        }
      }else{
        Toast({message:"扫描错误，请重新扫描！",duration:3000})
      }
    },
    async getDetail(orderNo){
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let param ={
        orderNo:orderNo
      };
      const res = await getOrderDetailInfoByNo(param);
      Toast.clear();
      if(res.code===0){
        Toast({message:"扫描成功",duration:3000})
         this.list = res.data.recOrderInfoDetailList;
         this.list.forEach((item,index,array)=> {
           item.completeNumber = 0;
         })
      }else{
        Toast({message:"扫描错误，请重新扫描！",duration:3000})
      }
    },
    async outMessage(){
      if(!/^\d+$/.test(this.orderNo)||this.storeNos.length===0){
        Toast("请您先进行扫描");
        return;
      }
      let result = this.list.filter((item) => {
        return item.completeNumber!==item.goodsNumber;
      });
      if(result.length!==0){
        Toast("请完成扫描");
        return;
      }
      Toast.loading({
        message: '出库中...',
        forbidClick: true
      });
      let param ={
        orderNo:this.orderNo,
        storeNos:this.storeNos.join(',')
      };
      const res = await submitOutStorage(param);
      Toast.clear();
      if(res.code===0){
        Toast.success({message:"出库成功",duration:2000})
        this.list = [];
        this.$router.push({path:'/success',query:{str:'出库'}})
      }else{
        Toast.fail({message:res.message+",请重新尝试",duration:3000})
      }
    }
  }
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import '../../common/style/mixin';
.content{
  padding: 48px 60px 0 64px;
    .content-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content-title-left{
        height: 42px;
        font-size: 32px;
        font-family: Yu Gothic UI;
        font-weight: bold;
        color: #000000;
        opacity: 1;
      }
      .content-title-right{
        button{
          width: 136px;
          height: 60px;
          border-radius: 40px;
          opacity: 1;
        }
        button:nth-of-type(1){
          background: #B74134;
          color: #fff;
        }
      }
    }
  .shelf-code{
    margin-top: 20px;
    height: 80px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    opacity: 1;
    border-radius: 0px;
    padding: 20px;
    font-size: 28px;
    font-family: Yu Gothic UI;
    font-weight: bold;
    color: #393C41;
    opacity: 1;
  }

  .content-center{
    margin-top: 50px;
    .content-text-all{
      height: 600px;
      overflow-y: auto;
      .content-text{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        padding:16px 0;
        align-items: center;
        border-bottom: 2px solid #F4F4F4;
        .content-text-img{
          margin-right: 32px;
          img{
            width: 40px;
            height: 40px;
          }
        }
        .content-text-center{
          font-size: 28px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          color: #393C41;
          opacity: 1;
          word-wrap: break-word;
          word-break:break-all;
        }
        .content-text-right{
          font-size: 28px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          color: #393C41;
          opacity: 1;
          padding-left: 36px;
          span{
            margin: 0 5px;
          }
        }
      }
    }
  }
  .empty{
    margin-top: 24px;
    text-align: center;
    color: #9FA2A8;
    font-weight: 400;
    font-size: 24px;
    padding: 18px;
    align-items: center;
  }
}
.notarize{
  position: fixed;
  bottom: 160px;
  width: 534px;
  left: 50%;
  transform: translateX(-50%);
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}

</style>
